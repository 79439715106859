import { Injectable } from '@angular/core';
import {SecurityControllerService} from 'src/app/api/figtionary2-service/services/security-controller.service'
import { UserDefinitionDto, MediaDto, UserNoteDto, UserDto } from '../api/figtionary2-service/models';
import {DictionaryControllerService} from 'src/app/api/figtionary2-service/services/dictionary-controller.service';
import {PracticeControllerService} from '../api/figtionary2-service/services/practice-controller.service';
import { TaskQueueControllerService } from '../api/figtionary2-service/services/task-queue-controller.service';
import { LocalStorageService } from './local-storage.service';
import { UserNotesControllerService, UserControllerService, DeckControllerService } from '../api/figtionary2-service/services';
import { AuthenticateControllerService } from '../api/figtionary2-service/services';

@Injectable({
  providedIn: 'root'
})
export class Figtionary2DataService {


  constructor(private securityControllerService: SecurityControllerService,
    private dictionaryControllerService: DictionaryControllerService,
    private practiceControllerService:PracticeControllerService,
    //private taskQueueControllerService: TaskQueueControllerService,
    private userNotesControllerService: UserNotesControllerService,
    //private localStorageService: LocalStorageService,
    private authenticateControllerService: AuthenticateControllerService,
    private userControllerService: UserControllerService,
    private deckControllerService: DeckControllerService
  ) { }

  

  getUserByExternalAuthUserId(externalAuthUserId: string){
    return this.securityControllerService.getUserByExternalAuthUserIdUsingPOST(externalAuthUserId);
  }
  /*
  registerTempUser(): Observable<number>{
    return this.securityControllerService.registerTempUserUsingPOST();
  }
  */

  register(externalAuthUserId: string, emailAddress: string){
    let params = <SecurityControllerService.RegisterUsingPOSTParams>{};
    params.emailAddress = emailAddress;
    params.externalAuthUserId = externalAuthUserId;
    return this.securityControllerService.registerUsingPOST(params);
  }

  saveUser(userDto: UserDto){
    let params = <SecurityControllerService.SaveUsingPOSTParams>{};
    params.firstName = userDto.firstName;
    params.lastName = userDto.lastName;
    params.emailAddress = userDto.emailAddress;
    params.id = userDto.id;
    params.preferredDictionaryId = userDto.preferredDictionaryId;
    return this.securityControllerService.saveUsingPOST(params);
  }

  

  saveUserDefinition(userDefinition: UserDefinitionDto){
    let params = <UserNotesControllerService.SaveUserDefinitionsUsingPUTParams>{};
    params.word = userDefinition.word;
    params.mediaUrl = userDefinition.media.url;
    params.text = userDefinition.text;
    params.createdById = userDefinition.createdBy.id;
    params.id = userDefinition.id;

    return this.userNotesControllerService.saveUserDefinitionsUsingPUT(params);
  }

  deleteUserDefinition(id: number, userId: number){
    let params = <UserNotesControllerService.DeleteUserDefinitionUsingDELETEParams>{};
    params.userId = userId;
    params.userDefinitionId = id;
    return this.userNotesControllerService.deleteUserDefinitionUsingDELETE(params);
  }

  saveUserNote(userNote: UserNoteDto){
    let params = <UserNotesControllerService.SaveUserNoteUsingPUTParams>{};
    params.text = userNote.text;
    params.word = userNote.word;
    params.userId = userNote.userId;
    params.id = userNote.id;

    return this.userNotesControllerService.saveUserNoteUsingPUT(params);
  }

  lookup(word: string, user: UserDto){
    let params = <DictionaryControllerService.LookUpUsingGETParams>{};
    params.word = word;
    if(user)
      params.userId = user.id;
    return this.dictionaryControllerService.lookUpUsingGET(params);
  }

  getUserDashboard(userId: number){
    return this.userNotesControllerService.getUserDashboardUsingGET(userId);
  }

  getPracticeDashboard(userId: number){
    return this.userNotesControllerService.getUserPracticeDashboardUsingGET(userId);
  }

  getReview(userId: number){
    return this.practiceControllerService.getReviewUsingGET(userId);
  }

  getReviewByTag(userId: number, tagText: string){
    let params = <PracticeControllerService.GetReviewByTagUsingGETParams>{};
    params.tagText = tagText;
    params.userId = userId;
    return this.practiceControllerService.getReviewByTagUsingGET(params);
  }

  practiced(passed: boolean, wordSearchId: number, userId: number){
    let params = <PracticeControllerService.PracticedUsingPOSTParams>{};
    params.passed = passed;
    params.wordSearchId = wordSearchId;
    params.userId = userId;
    return this.practiceControllerService.practicedUsingPOST(params);
  }

  saveProfilePicture(url: string, userId: number){
    let params = <SecurityControllerService.SaveProfilePictureUsingPOSTParams>{};
    params.userId = userId;
    params.url = url;
    return this.securityControllerService.saveProfilePictureUsingPOST(params);
  }

  findWords(searchText: string, userId: number){
    let params = <UserNotesControllerService.FindWordsUsingGETParams>{};
    params.userId = userId;
    params.text = searchText;
    return this.userNotesControllerService.findWordsUsingGET(params);
  }

  saveTags(word: string, tags: string[], userId: number){
    let params = <UserNotesControllerService.SaveUserWordTagsUsingPUTParams>{};
    params.userId = userId;
    params.word = word;
    params.tags = tags;
    return this.userNotesControllerService.saveUserWordTagsUsingPUT(params);
  }


  deleteWordSearch(wordSearchId: number, userId: number){
    let params = <PracticeControllerService.DeleteWordSearchUsingDELETEParams>{};
    params.userId = userId;
    params.wordSearchId = wordSearchId;

    return this.practiceControllerService.deleteWordSearchUsingDELETE(params);
  }

  remembered(wordSearchId: number, userId: number){
    let params = <PracticeControllerService.RememberedUsingPOSTParams>{};
    params.userId = userId;
    params.wordSearchId = wordSearchId;

    return this.practiceControllerService.rememberedUsingPOST(params);
  }

  getDictionaryList(){
    return this.dictionaryControllerService.dictionariesUsingGET(); 
  }

  getUserTags(userId: number){
    return this.userNotesControllerService.getUserTagsUsingGET(userId);
  }

  findWordsByTag(userId: number, tagText: string){
    let params: UserNotesControllerService.FindWordsByTagUsingGETParams = 
      <UserNotesControllerService.FindWordsByTagUsingGETParams>{};
    
    params.userId = userId;
    params.tagText = tagText;
    return this.userNotesControllerService.findWordsByTagUsingGET(params);
  }


  getWordOfTheDay(userId: number){
    return this.practiceControllerService.getWordOfTheDayUsingGET(userId);
  }

  tokens(refreshToken: string){
    return this.authenticateControllerService.tokensUsingGET(refreshToken);
  }

  shouldUpdateApp(){
    let params = <AuthenticateControllerService.ShouldUpdateAppUsingGETParams>{};
    return this.authenticateControllerService.shouldUpdateAppUsingGET(params);
  }

  reviveForgottenWords(userId: number, numWords: number){
    let params = <PracticeControllerService.ReviveForgottenWordsUsingPOSTParams>{};
    params.numWords = numWords;
    params.userId = userId;
    return this.practiceControllerService.reviveForgottenWordsUsingPOST(params);
  }

  reviveForgottenWordsByTag(userId: number, tagText: string){
    let params = <PracticeControllerService.RestoreWordsCollectionByTagUsingPOSTParams>{};
    params.userId = userId;
    params.tagText = tagText;
    return this.practiceControllerService.restoreWordsCollectionByTagUsingPOST(params);
  }

  getAllWordsCollections(userId: number){
    return this.practiceControllerService.allWordsCollectionsUsingGET(userId);
  }

  purchase(userId: number, wordsCollectionId: number){
    let params = <UserControllerService.PurchaseUsingPUTParams>{};
    params.userId = userId;
    params.wordsCollectionId = wordsCollectionId;
    return this.userControllerService.purchaseUsingPUT(params);
  }

  restore(userId: number, wordsCollectionId: number){
    let params = <PracticeControllerService.RestoreWordsCollectionUsingPOSTParams>{};
    params.userId = userId;
    params.collectionId = wordsCollectionId;
    return this.practiceControllerService.restoreWordsCollectionUsingPOST(params);
  }

  getDecks(userId: number){
    return this.deckControllerService.getDecksUsingGET(userId);
  }
}
