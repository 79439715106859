import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private i18n: I18nService,
    private toastController: ToastController
    ) {

  }


  async presentLoading(msg: string, loadingController: LoadingController) {
    let translated = this.i18n.getText(msg);

    if (translated == null || translated.trim().length == 0) {
      translated = "";
    } else {
      translated = translated + " ...";
    }
    let loading = await loadingController.create({
      message: translated,
      duration: 30000
    });
    await loading.present();
  }

  async showSuccessToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 4000,
      position: "top",
      color: "success",
      cssClass: "success-toast",
      translucent: true
    });
    toast.present();
  }
}
