import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {UserDefinitionDto} from 'src/app/api/figtionary2-service/models/user-definition-dto';
import { Figtionary2DataService } from 'src/app/data/figtionary2-data.service';
import { DictionaryControllerService } from 'src/app/api/figtionary2-service/services';
import { MediaDto } from 'src/app/api/figtionary2-service/models';


var that: SaveDefinitionModalPage;

@Component({
  selector: 'app-save-definition-modal',
  templateUrl: './save-definition-modal.page.html',
  styleUrls: ['./save-definition-modal.page.scss'],
})
export class SaveDefinitionModalPage implements OnInit {
  _userDefinition: UserDefinitionDto;
  mediaUrl: string;
  text: string;
  
  @Input()
  set userDefinition(userDef: UserDefinitionDto){
    this._userDefinition = userDef;
    this.mediaUrl = userDef.media.url;
    this.text = userDef.text;
  }

  get userDefinition(){
    return this._userDefinition;
  }
  
  

  constructor(private modalCtrl: ModalController, 
    private figtionary2DataService: Figtionary2DataService,
    ) { 
    
  }

  ngOnInit() {
    console.log(JSON.stringify(this.userDefinition));
    that = this;
  }

  cancel(){
    console.log(JSON.stringify(this.userDefinition));
    this.modalCtrl.dismiss();
  }

  save(){
    console.log("saving definition");
    that._userDefinition.text = that.text;
    that._userDefinition.media.url = that.mediaUrl;
    this.figtionary2DataService.saveUserDefinition(that._userDefinition).subscribe(
      (response)=>{
        let userDef = response;
        console.log(JSON.stringify(userDef));
        if(that.userDefinition.media.url != null && that.userDefinition.media.url.length > 0){
          // if the url is not empty, youTubeInfo may have changed.
          that.userDefinition.media.youTubeInfo = userDef.media.youTubeInfo;
        }
        that._userDefinition = userDef;
        this.modalCtrl.dismiss(userDef);
      }, error=>{
        
      }
    )
  }

}
