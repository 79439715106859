/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AuthenticateControllerService } from './services/authenticate-controller.service';
import { DataManagementControllerService } from './services/data-management-controller.service';
import { DeckControllerService } from './services/deck-controller.service';
import { DictionaryControllerService } from './services/dictionary-controller.service';
import { PracticeControllerService } from './services/practice-controller.service';
import { SecurityControllerService } from './services/security-controller.service';
import { TaskQueueControllerService } from './services/task-queue-controller.service';
import { UserNotesControllerService } from './services/user-notes-controller.service';
import { UserControllerService } from './services/user-controller.service';
import { BasicErrorControllerService } from './services/basic-error-controller.service';
import { ZombieControllerService } from './services/zombie-controller.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AuthenticateControllerService,
    DataManagementControllerService,
    DeckControllerService,
    DictionaryControllerService,
    PracticeControllerService,
    SecurityControllerService,
    TaskQueueControllerService,
    UserNotesControllerService,
    UserControllerService,
    BasicErrorControllerService,
    ZombieControllerService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
