import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'dictionary',
    loadChildren: './pages/dictionary/dictionary.module#DictionaryPageModule'
  },
  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule' },
  { path: 'hangman', loadChildren: './pages/hangman/hangman.module#HangmanPageModule' },
  { path: 'find-my-word', loadChildren: './pages/find-my-word/find-my-word.module#FindMyWordPageModule' },
  { path: 'find-my-word-by-tag', loadChildren: './pages/find-my-word-by-tag/find-my-word-by-tag.module#FindMyWordByTagPageModule' },
  //{ path: 'dictionary', loadChildren: './pages/dictionary/dictionary.module#DictionaryPageModule' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'route/:message', loadChildren: './pages/route/route.module#RoutePageModule' },
  { path: 'word-of-the-day', loadChildren: './pages/word-of-the-day/word-of-the-day.module#WordOfTheDayPageModule' },
  { path: 'hangman-by-tag', loadChildren: './pages/hangman-by-tag/hangman-by-tag.module#HangmanByTagPageModule' },
  { path: 'outdated', loadChildren: './pages/outdated/outdated.module#OutdatedPageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
  { path: 'reset-password', loadChildren: './pages/reset-password/reset-password.module#ResetPasswordPageModule' },
  { path: 'support', loadChildren: './pages/support/support.module#SupportPageModule' },
  { path: 'game', loadChildren: './pages/game/game.module#GamePageModule' },
  { path: 'anagram', loadChildren: './pages/anagram/anagram.module#AnagramPageModule' },
  { path: 'prep-exams', loadChildren: './pages/prep-exams/prep-exams.module#PrepExamsPageModule' },
  { path: 'anagram-by-tag', loadChildren: './pages/anagram-by-tag/anagram-by-tag.module#AnagramByTagPageModule' },
  { path: 'landing', loadChildren: './pages/landing/landing.module#LandingPageModule' },  { path: 'flashcard', loadChildren: './pages/flashcard/flashcard.module#FlashcardPageModule' },
  { path: 'decks', loadChildren: './pages/decks/decks.module#DecksPageModule' },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
