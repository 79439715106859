import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserDto, UserDashboardDto } from '../api/figtionary2-service/models';
import { User } from 'firebase';
import { Observable, Observer } from 'rxjs';
import { EncryptorService } from './encryptor.service';
import { Router } from '@angular/router';
import { nextContext } from '@angular/core/src/render3';

var that: LocalStorageService;

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  STORAGE_KEY_USER: string = "user";
  STORAGE_KEY_DASHBOARD = "userDashboard";
  STORAGE_KEY_LANG = "Lang";
  ENCRYPT_KEY = "UofM@1999";
  user: UserDto;

  constructor(private storage: Storage
    , private encryptor: EncryptorService
    , private router: Router
  ) {
    that = this;
  }

  getUser(): Observable<UserDto> {
    return Observable.create((observer: Observer<any>) => {
      if (that.user != null) {
        observer.next(that.user);
      } else {
        this.storage.get(this.STORAGE_KEY_USER).then(
          (userJson: string) => {
            var user;
            if (userJson == null) {
              user = null;
            } else {
              if (userJson.startsWith("{") && userJson.endsWith("}")) {
                this.setUser(null);
                this.router.navigate(['profile'], {});
                return;
              }
              let userJsonDecrypted = this.encryptor.decrypt(this.ENCRYPT_KEY, userJson);
              
              user = JSON.parse(userJsonDecrypted);
            }
            that.user = user;
            observer.next(user);
            observer.complete();
          }
        ).catch(error => {
          observer.error(error);
        });
      }
    });
  }

  getUserPromise(){
    var promise = new Promise<UserDto>(
      (resolve, reject)=>{
        if (that.user != null) {
          resolve(that.user);
        } else {
          this.storage.get(this.STORAGE_KEY_USER).then(
            (userJson: string) => {
              var user;
              if (userJson == null) {
                user = null;
              } else {
                if (userJson.startsWith("{") && userJson.endsWith("}")) {
                  that.setUser(null);
                  this.router.navigate(['profile'], {});
                  return;
                }
                let userJsonDecrypted = this.encryptor.decrypt(this.ENCRYPT_KEY, userJson);
                
                user = JSON.parse(userJsonDecrypted);
              }
              that.user = user;
              resolve(user);              
            }
          ).catch(error => {
            reject(error);
          });
        }
      }
      
    )
    return promise;
  }

  setUser(user: UserDto) {
    that.user = user;
    if (user == null) {
      this.storage.set(this.STORAGE_KEY_USER, null);
    } else {
      let userJson = JSON.stringify(user);
      let userJsonEncrypted = this.encryptor.encrypt(this.ENCRYPT_KEY, userJson);
      //let userJsonEncrypted = userJson; // for testing with figtionary without encryption
      this.storage.set(this.STORAGE_KEY_USER, userJsonEncrypted);
    }
  }

  setUserDashboard(dashboard: UserDashboardDto) {
    if (dashboard == null) {
      this.storage.set(this.STORAGE_KEY_DASHBOARD, null);
    } else {
      this.storage.set(this.STORAGE_KEY_DASHBOARD, JSON.stringify(dashboard));
    }
  }

  getUserDashboard() {
    return this.storage.get(this.STORAGE_KEY_DASHBOARD).then(
      (success) => {
        var userDashboard: UserDashboardDto = JSON.parse(success);
        return userDashboard;
      }
    )
  }

  setLang(lang: string){
    this.storage.set(this.STORAGE_KEY_LANG, lang);
  }

  getLang(){
    return this.storage.get(this.STORAGE_KEY_LANG);
  }
}
