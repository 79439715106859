/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Data Management Controller
 */
@Injectable({
  providedIn: 'root',
})
class DataManagementControllerService extends __BaseService {
  static readonly pingUsingGETPath = '/api/datamigration/_ping';
  static readonly deleteIndexDocumentUsingDELETEPath = '/api/datamigration/deleteIndexDocument';
  static readonly deleteUnwantedUserDataUsingGETPath = '/api/datamigration/deleteUnwantedUserData';
  static readonly deleteUserDefWordSearchUsingPUT1Path = '/api/datamigration/deleteUserDefWordSearch';
  static readonly deleteUserDefWordSearchUsingPUTPath = '/api/datamigration/deleteUserDefWordSearchByUser';
  static readonly dinkyUrlUseImagesSubdomainUsingGETPath = '/api/datamigration/dinkyUrlUseImagesSubdomain';
  static readonly fixUserDefinitionMediaUsingGETPath = '/api/datamigration/fixUserDefinitionMedia';
  static readonly fixUserWordTagUsingPUTPath = '/api/datamigration/fixUserWordTag';
  static readonly fixWordSearchesTagsNotIndexedUsingPUTPath = '/api/datamigration/fixWordSearchesTagsNotIndexed';
  static readonly migrateTagsUsingGETPath = '/api/datamigration/migrateTags';
  static readonly migrateWordLookupsUsingGETPath = '/api/datamigration/migrateWordLookups';
  static readonly setPreferredDictionaryToNullUsingPUTPath = '/api/datamigration/setPreferredDictionaryToNull';
  static readonly setupWordsCollectionUsingPUTPath = '/api/datamigration/setupWordsCollection';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * ping
   * @return OK
   */
  pingUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/datamigration/_ping`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * ping
   * @return OK
   */
  pingUsingGET(): __Observable<string> {
    return this.pingUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * deleteIndexDocument
   * @param params The `DataManagementControllerService.DeleteIndexDocumentUsingDELETEParams` containing the following parameters:
   *
   * - `wordOrDocumentId`: wordOrDocumentId
   *
   * - `userId`: userId
   */
  deleteIndexDocumentUsingDELETEResponse(params: DataManagementControllerService.DeleteIndexDocumentUsingDELETEParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wordOrDocumentId != null) __params = __params.set('wordOrDocumentId', params.wordOrDocumentId.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/datamigration/deleteIndexDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * deleteIndexDocument
   * @param params The `DataManagementControllerService.DeleteIndexDocumentUsingDELETEParams` containing the following parameters:
   *
   * - `wordOrDocumentId`: wordOrDocumentId
   *
   * - `userId`: userId
   */
  deleteIndexDocumentUsingDELETE(params: DataManagementControllerService.DeleteIndexDocumentUsingDELETEParams): __Observable<null> {
    return this.deleteIndexDocumentUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * deleteUnwantedUserData
   * @param userId userId
   * @return OK
   */
  deleteUnwantedUserDataUsingGETResponse(userId?: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/datamigration/deleteUnwantedUserData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * deleteUnwantedUserData
   * @param userId userId
   * @return OK
   */
  deleteUnwantedUserDataUsingGET(userId?: number): __Observable<{}> {
    return this.deleteUnwantedUserDataUsingGETResponse(userId).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * deleteUserDefWordSearch
   * @param word word
   * @return OK
   */
  deleteUserDefWordSearchUsingPUT1Response(word?: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (word != null) __params = __params.set('word', word.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/datamigration/deleteUserDefWordSearch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * deleteUserDefWordSearch
   * @param word word
   * @return OK
   */
  deleteUserDefWordSearchUsingPUT1(word?: string): __Observable<{}> {
    return this.deleteUserDefWordSearchUsingPUT1Response(word).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * deleteUserDefWordSearch
   * @param params The `DataManagementControllerService.DeleteUserDefWordSearchUsingPUTParams` containing the following parameters:
   *
   * - `word`: word
   *
   * - `userId`: userId
   *
   * @return OK
   */
  deleteUserDefWordSearchUsingPUTResponse(params: DataManagementControllerService.DeleteUserDefWordSearchUsingPUTParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.word != null) __params = __params.set('word', params.word.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/datamigration/deleteUserDefWordSearchByUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * deleteUserDefWordSearch
   * @param params The `DataManagementControllerService.DeleteUserDefWordSearchUsingPUTParams` containing the following parameters:
   *
   * - `word`: word
   *
   * - `userId`: userId
   *
   * @return OK
   */
  deleteUserDefWordSearchUsingPUT(params: DataManagementControllerService.DeleteUserDefWordSearchUsingPUTParams): __Observable<{}> {
    return this.deleteUserDefWordSearchUsingPUTResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * dinkyUrlUseImagesSubdomain
   * @param params The `DataManagementControllerService.DinkyUrlUseImagesSubdomainUsingGETParams` containing the following parameters:
   *
   * - `toDate`: toDate
   *
   * - `fromDate`: fromDate
   *
   * @return OK
   */
  dinkyUrlUseImagesSubdomainUsingGETResponse(params: DataManagementControllerService.DinkyUrlUseImagesSubdomainUsingGETParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toDate != null) __params = __params.set('toDate', params.toDate.toString());
    if (params.fromDate != null) __params = __params.set('fromDate', params.fromDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/datamigration/dinkyUrlUseImagesSubdomain`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * dinkyUrlUseImagesSubdomain
   * @param params The `DataManagementControllerService.DinkyUrlUseImagesSubdomainUsingGETParams` containing the following parameters:
   *
   * - `toDate`: toDate
   *
   * - `fromDate`: fromDate
   *
   * @return OK
   */
  dinkyUrlUseImagesSubdomainUsingGET(params: DataManagementControllerService.DinkyUrlUseImagesSubdomainUsingGETParams): __Observable<{}> {
    return this.dinkyUrlUseImagesSubdomainUsingGETResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * fixUserDefinitionMedia
   * @param params The `DataManagementControllerService.FixUserDefinitionMediaUsingGETParams` containing the following parameters:
   *
   * - `toDate`: toDate
   *
   * - `fromDate`: fromDate
   *
   * - `numToFix`: numToFix
   *
   * @return OK
   */
  fixUserDefinitionMediaUsingGETResponse(params: DataManagementControllerService.FixUserDefinitionMediaUsingGETParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toDate != null) __params = __params.set('toDate', params.toDate.toString());
    if (params.fromDate != null) __params = __params.set('fromDate', params.fromDate.toString());
    if (params.numToFix != null) __params = __params.set('numToFix', params.numToFix.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/datamigration/fixUserDefinitionMedia`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * fixUserDefinitionMedia
   * @param params The `DataManagementControllerService.FixUserDefinitionMediaUsingGETParams` containing the following parameters:
   *
   * - `toDate`: toDate
   *
   * - `fromDate`: fromDate
   *
   * - `numToFix`: numToFix
   *
   * @return OK
   */
  fixUserDefinitionMediaUsingGET(params: DataManagementControllerService.FixUserDefinitionMediaUsingGETParams): __Observable<{}> {
    return this.fixUserDefinitionMediaUsingGETResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * fixUserWordTag
   * @param params The `DataManagementControllerService.FixUserWordTagUsingPUTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tag`: tag
   *
   * @return OK
   */
  fixUserWordTagUsingPUTResponse(params: DataManagementControllerService.FixUserWordTagUsingPUTParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.tag != null) __params = __params.set('tag', params.tag.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/datamigration/fixUserWordTag`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * fixUserWordTag
   * @param params The `DataManagementControllerService.FixUserWordTagUsingPUTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tag`: tag
   *
   * @return OK
   */
  fixUserWordTagUsingPUT(params: DataManagementControllerService.FixUserWordTagUsingPUTParams): __Observable<Array<string>> {
    return this.fixUserWordTagUsingPUTResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * fixWordSearchesTagsNotIndexed
   * @return OK
   */
  fixWordSearchesTagsNotIndexedUsingPUTResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/datamigration/fixWordSearchesTagsNotIndexed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * fixWordSearchesTagsNotIndexed
   * @return OK
   */
  fixWordSearchesTagsNotIndexedUsingPUT(): __Observable<number> {
    return this.fixWordSearchesTagsNotIndexedUsingPUTResponse().pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * migrateTags
   * @param maxNumToMigrate maxNumToMigrate
   * @return OK
   */
  migrateTagsUsingGETResponse(maxNumToMigrate?: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (maxNumToMigrate != null) __params = __params.set('maxNumToMigrate', maxNumToMigrate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/datamigration/migrateTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * migrateTags
   * @param maxNumToMigrate maxNumToMigrate
   * @return OK
   */
  migrateTagsUsingGET(maxNumToMigrate?: number): __Observable<{}> {
    return this.migrateTagsUsingGETResponse(maxNumToMigrate).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * migrateWordLookups
   * @param numLookupsToMigrate numLookupsToMigrate
   * @return OK
   */
  migrateWordLookupsUsingGETResponse(numLookupsToMigrate?: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (numLookupsToMigrate != null) __params = __params.set('numLookupsToMigrate', numLookupsToMigrate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/datamigration/migrateWordLookups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * migrateWordLookups
   * @param numLookupsToMigrate numLookupsToMigrate
   * @return OK
   */
  migrateWordLookupsUsingGET(numLookupsToMigrate?: number): __Observable<{}> {
    return this.migrateWordLookupsUsingGETResponse(numLookupsToMigrate).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * setPreferredDictionaryToNull
   * @return OK
   */
  setPreferredDictionaryToNullUsingPUTResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/datamigration/setPreferredDictionaryToNull`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * setPreferredDictionaryToNull
   * @return OK
   */
  setPreferredDictionaryToNullUsingPUT(): __Observable<string> {
    return this.setPreferredDictionaryToNullUsingPUTResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * setupWordsCollection
   * @return OK
   */
  setupWordsCollectionUsingPUTResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/datamigration/setupWordsCollection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * setupWordsCollection
   * @return OK
   */
  setupWordsCollectionUsingPUT(): __Observable<string> {
    return this.setupWordsCollectionUsingPUTResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module DataManagementControllerService {

  /**
   * Parameters for deleteIndexDocumentUsingDELETE
   */
  export interface DeleteIndexDocumentUsingDELETEParams {

    /**
     * wordOrDocumentId
     */
    wordOrDocumentId?: string;

    /**
     * userId
     */
    userId?: number;
  }

  /**
   * Parameters for deleteUserDefWordSearchUsingPUT
   */
  export interface DeleteUserDefWordSearchUsingPUTParams {

    /**
     * word
     */
    word?: string;

    /**
     * userId
     */
    userId?: number;
  }

  /**
   * Parameters for dinkyUrlUseImagesSubdomainUsingGET
   */
  export interface DinkyUrlUseImagesSubdomainUsingGETParams {

    /**
     * toDate
     */
    toDate: string;

    /**
     * fromDate
     */
    fromDate: string;
  }

  /**
   * Parameters for fixUserDefinitionMediaUsingGET
   */
  export interface FixUserDefinitionMediaUsingGETParams {

    /**
     * toDate
     */
    toDate: string;

    /**
     * fromDate
     */
    fromDate: string;

    /**
     * numToFix
     */
    numToFix?: number;
  }

  /**
   * Parameters for fixUserWordTagUsingPUT
   */
  export interface FixUserWordTagUsingPUTParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * tag
     */
    tag?: string;
  }
}

export { DataManagementControllerService }
