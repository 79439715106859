import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, concat, of, timer, Observer } from 'rxjs';
import { tap, catchError, retry, retryWhen, delay, take, map, delayWhen } from 'rxjs/operators';
import { SharedDataService } from 'src/app/data/shared-data.service';
import { I18nService } from '../data/i18n.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private sharedData: SharedDataService,
    private i18n: I18nService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = req.url;
    if(url.endsWith("/tokens")){
      return this.interceptTokens(req, next);
    }else{
      return this.interceptNonTokens(req, next);
    }
  }

  private interceptTokens(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = {
      //'Authorization': "Basic " + btoa("user:password"),
      //'Authorization': 'Bearer ' + this.sharedData.idToken,
      'App-Version': "" + this.sharedData.appVersion,
      'App': "Figtionary"
    };
    let headerStr = JSON.stringify(headers);
    let url = req.url;
    let message = `Url: ${url} headers:${headerStr} `;
    //console.log(message);

    req = req.clone({
      setHeaders: headers
    });

    return next.handle(req).pipe(
      retryWhen(
        error => error.pipe(
          delay(3000),
          take(1),
          tap(error => {
            console.log(error);
            alert(this.i18n.getText(this.i18n.KEY_SOMETHING_WENT_WRONG));
            throw error;
          })
        )
      )
    );
  }

  private interceptNonTokens(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("^^^^ intercept non tokens request");
    return Observable.create((observer: Observer<HttpEvent<any>>) => {
      this.sharedData.idTokenMutex.runExclusive(
        async () => {
          console.log("^^^^ in runExclusive")
          let url = req.url;
          let headers = {
            'Authorization': 'Bearer ' + this.sharedData.idToken,
            'App-Version': "" + this.sharedData.appVersion,
            'App': "Figtionary"
          };
          let headersJson = JSON.stringify(headers);
          //console.log(`url: ${url}, headers: ${headersJson}`);
          req = req.clone({
            setHeaders: headers
          });

          var ret = next.handle(req).pipe(
            //retry(1),
            retryWhen(
              error => error.pipe(
                delay(2000), 
                take(1),
                tap(error => {
                  console.log(error);
                  alert(this.i18n.getText(this.i18n.KEY_SOMETHING_WENT_WRONG));
                  observer.error(error);
                })
              )
            ),
          );
          ret.subscribe(
            (httpEvent) => {
              observer.next(httpEvent);
            }
          )          
        }
      )
    });
  }
}