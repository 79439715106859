/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WordsCollectionDto } from '../models/words-collection-dto';
import { ReviewDto } from '../models/review-dto';
import { UserDashboardDto } from '../models/user-dashboard-dto';
import { WordLookup } from '../models/word-lookup';
import { WordOfTheDayDto } from '../models/word-of-the-day-dto';

/**
 * Practice Controller
 */
@Injectable({
  providedIn: 'root',
})
class PracticeControllerService extends __BaseService {
  static readonly allWordsCollectionsUsingGETPath = '/api/practice/allWordsCollections';
  static readonly practicedUsingPOSTPath = '/api/practice/practiced';
  static readonly rememberedUsingPOSTPath = '/api/practice/remembered';
  static readonly getReviewUsingGETPath = '/api/practice/review';
  static readonly getReviewByTagUsingGETPath = '/api/practice/reviewByTag';
  static readonly reviveForgottenWordsUsingPOSTPath = '/api/practice/reviveForgottenWords';
  static readonly restoreWordsCollectionUsingPOSTPath = '/api/practice/reviviWordsCollection';
  static readonly restoreWordsCollectionByTagUsingPOSTPath = '/api/practice/reviviWordsCollectionByTag';
  static readonly getWordLookupUsingGETPath = '/api/practice/wordLookup';
  static readonly saveWordLookupUsingPOSTPath = '/api/practice/wordLookup';
  static readonly getWordOfTheDayUsingGETPath = '/api/practice/wordOfTheDay';
  static readonly deleteWordSearchUsingDELETEPath = '/api/practice/wordSearches';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * allWordsCollections
   * @param userId userId
   * @return OK
   */
  allWordsCollectionsUsingGETResponse(userId?: number): __Observable<__StrictHttpResponse<Array<WordsCollectionDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/practice/allWordsCollections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WordsCollectionDto>>;
      })
    );
  }
  /**
   * allWordsCollections
   * @param userId userId
   * @return OK
   */
  allWordsCollectionsUsingGET(userId?: number): __Observable<Array<WordsCollectionDto>> {
    return this.allWordsCollectionsUsingGETResponse(userId).pipe(
      __map(_r => _r.body as Array<WordsCollectionDto>)
    );
  }

  /**
   * practiced
   * @param params The `PracticeControllerService.PracticedUsingPOSTParams` containing the following parameters:
   *
   * - `wordSearchId`: wordSearchId
   *
   * - `userId`: userId
   *
   * - `passed`: passed
   */
  practicedUsingPOSTResponse(params: PracticeControllerService.PracticedUsingPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wordSearchId != null) __params = __params.set('wordSearchId', params.wordSearchId.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.passed != null) __params = __params.set('passed', params.passed.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/practice/practiced`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * practiced
   * @param params The `PracticeControllerService.PracticedUsingPOSTParams` containing the following parameters:
   *
   * - `wordSearchId`: wordSearchId
   *
   * - `userId`: userId
   *
   * - `passed`: passed
   */
  practicedUsingPOST(params: PracticeControllerService.PracticedUsingPOSTParams): __Observable<null> {
    return this.practicedUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * remembered
   * @param params The `PracticeControllerService.RememberedUsingPOSTParams` containing the following parameters:
   *
   * - `wordSearchId`: wordSearchId
   *
   * - `userId`: userId
   */
  rememberedUsingPOSTResponse(params: PracticeControllerService.RememberedUsingPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wordSearchId != null) __params = __params.set('wordSearchId', params.wordSearchId.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/practice/remembered`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * remembered
   * @param params The `PracticeControllerService.RememberedUsingPOSTParams` containing the following parameters:
   *
   * - `wordSearchId`: wordSearchId
   *
   * - `userId`: userId
   */
  rememberedUsingPOST(params: PracticeControllerService.RememberedUsingPOSTParams): __Observable<null> {
    return this.rememberedUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * getReview
   * @param userId userId
   * @return OK
   */
  getReviewUsingGETResponse(userId?: number): __Observable<__StrictHttpResponse<ReviewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/practice/review`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReviewDto>;
      })
    );
  }
  /**
   * getReview
   * @param userId userId
   * @return OK
   */
  getReviewUsingGET(userId?: number): __Observable<ReviewDto> {
    return this.getReviewUsingGETResponse(userId).pipe(
      __map(_r => _r.body as ReviewDto)
    );
  }

  /**
   * getReviewByTag
   * @param params The `PracticeControllerService.GetReviewByTagUsingGETParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  getReviewByTagUsingGETResponse(params: PracticeControllerService.GetReviewByTagUsingGETParams): __Observable<__StrictHttpResponse<ReviewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.tagText != null) __params = __params.set('tagText', params.tagText.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/practice/reviewByTag`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReviewDto>;
      })
    );
  }
  /**
   * getReviewByTag
   * @param params The `PracticeControllerService.GetReviewByTagUsingGETParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  getReviewByTagUsingGET(params: PracticeControllerService.GetReviewByTagUsingGETParams): __Observable<ReviewDto> {
    return this.getReviewByTagUsingGETResponse(params).pipe(
      __map(_r => _r.body as ReviewDto)
    );
  }

  /**
   * reviveForgottenWords
   * @param params The `PracticeControllerService.ReviveForgottenWordsUsingPOSTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `numWords`: numWords
   *
   * @return OK
   */
  reviveForgottenWordsUsingPOSTResponse(params: PracticeControllerService.ReviveForgottenWordsUsingPOSTParams): __Observable<__StrictHttpResponse<UserDashboardDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.numWords != null) __params = __params.set('numWords', params.numWords.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/practice/reviveForgottenWords`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDashboardDto>;
      })
    );
  }
  /**
   * reviveForgottenWords
   * @param params The `PracticeControllerService.ReviveForgottenWordsUsingPOSTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `numWords`: numWords
   *
   * @return OK
   */
  reviveForgottenWordsUsingPOST(params: PracticeControllerService.ReviveForgottenWordsUsingPOSTParams): __Observable<UserDashboardDto> {
    return this.reviveForgottenWordsUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as UserDashboardDto)
    );
  }

  /**
   * restoreWordsCollection
   * @param params The `PracticeControllerService.RestoreWordsCollectionUsingPOSTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `collectionId`: collectionId
   *
   * @return OK
   */
  restoreWordsCollectionUsingPOSTResponse(params: PracticeControllerService.RestoreWordsCollectionUsingPOSTParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.collectionId != null) __params = __params.set('collectionId', params.collectionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/practice/reviviWordsCollection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * restoreWordsCollection
   * @param params The `PracticeControllerService.RestoreWordsCollectionUsingPOSTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `collectionId`: collectionId
   *
   * @return OK
   */
  restoreWordsCollectionUsingPOST(params: PracticeControllerService.RestoreWordsCollectionUsingPOSTParams): __Observable<Array<string>> {
    return this.restoreWordsCollectionUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * restoreWordsCollectionByTag
   * @param params The `PracticeControllerService.RestoreWordsCollectionByTagUsingPOSTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  restoreWordsCollectionByTagUsingPOSTResponse(params: PracticeControllerService.RestoreWordsCollectionByTagUsingPOSTParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.tagText != null) __params = __params.set('tagText', params.tagText.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/practice/reviviWordsCollectionByTag`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * restoreWordsCollectionByTag
   * @param params The `PracticeControllerService.RestoreWordsCollectionByTagUsingPOSTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  restoreWordsCollectionByTagUsingPOST(params: PracticeControllerService.RestoreWordsCollectionByTagUsingPOSTParams): __Observable<Array<string>> {
    return this.restoreWordsCollectionByTagUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * getWordLookup
   * @param id id
   * @return OK
   */
  getWordLookupUsingGETResponse(id?: number): __Observable<__StrictHttpResponse<Array<WordLookup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/practice/wordLookup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WordLookup>>;
      })
    );
  }
  /**
   * getWordLookup
   * @param id id
   * @return OK
   */
  getWordLookupUsingGET(id?: number): __Observable<Array<WordLookup>> {
    return this.getWordLookupUsingGETResponse(id).pipe(
      __map(_r => _r.body as Array<WordLookup>)
    );
  }

  /**
   * saveWordLookup
   * @param id id
   * @return OK
   */
  saveWordLookupUsingPOSTResponse(id?: number): __Observable<__StrictHttpResponse<Array<WordLookup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/practice/wordLookup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WordLookup>>;
      })
    );
  }
  /**
   * saveWordLookup
   * @param id id
   * @return OK
   */
  saveWordLookupUsingPOST(id?: number): __Observable<Array<WordLookup>> {
    return this.saveWordLookupUsingPOSTResponse(id).pipe(
      __map(_r => _r.body as Array<WordLookup>)
    );
  }

  /**
   * getWordOfTheDay
   * @param userId userId
   * @return OK
   */
  getWordOfTheDayUsingGETResponse(userId?: number): __Observable<__StrictHttpResponse<WordOfTheDayDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/practice/wordOfTheDay`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WordOfTheDayDto>;
      })
    );
  }
  /**
   * getWordOfTheDay
   * @param userId userId
   * @return OK
   */
  getWordOfTheDayUsingGET(userId?: number): __Observable<WordOfTheDayDto> {
    return this.getWordOfTheDayUsingGETResponse(userId).pipe(
      __map(_r => _r.body as WordOfTheDayDto)
    );
  }

  /**
   * deleteWordSearch
   * @param params The `PracticeControllerService.DeleteWordSearchUsingDELETEParams` containing the following parameters:
   *
   * - `wordSearchId`: wordSearchId
   *
   * - `userId`: userId
   */
  deleteWordSearchUsingDELETEResponse(params: PracticeControllerService.DeleteWordSearchUsingDELETEParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wordSearchId != null) __params = __params.set('wordSearchId', params.wordSearchId.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/practice/wordSearches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * deleteWordSearch
   * @param params The `PracticeControllerService.DeleteWordSearchUsingDELETEParams` containing the following parameters:
   *
   * - `wordSearchId`: wordSearchId
   *
   * - `userId`: userId
   */
  deleteWordSearchUsingDELETE(params: PracticeControllerService.DeleteWordSearchUsingDELETEParams): __Observable<null> {
    return this.deleteWordSearchUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PracticeControllerService {

  /**
   * Parameters for practicedUsingPOST
   */
  export interface PracticedUsingPOSTParams {

    /**
     * wordSearchId
     */
    wordSearchId?: number;

    /**
     * userId
     */
    userId?: number;

    /**
     * passed
     */
    passed?: boolean;
  }

  /**
   * Parameters for rememberedUsingPOST
   */
  export interface RememberedUsingPOSTParams {

    /**
     * wordSearchId
     */
    wordSearchId?: number;

    /**
     * userId
     */
    userId?: number;
  }

  /**
   * Parameters for getReviewByTagUsingGET
   */
  export interface GetReviewByTagUsingGETParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * tagText
     */
    tagText?: string;
  }

  /**
   * Parameters for reviveForgottenWordsUsingPOST
   */
  export interface ReviveForgottenWordsUsingPOSTParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * numWords
     */
    numWords?: number;
  }

  /**
   * Parameters for restoreWordsCollectionUsingPOST
   */
  export interface RestoreWordsCollectionUsingPOSTParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * collectionId
     */
    collectionId?: number;
  }

  /**
   * Parameters for restoreWordsCollectionByTagUsingPOST
   */
  export interface RestoreWordsCollectionByTagUsingPOSTParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * tagText
     */
    tagText?: string;
  }

  /**
   * Parameters for deleteWordSearchUsingDELETE
   */
  export interface DeleteWordSearchUsingDELETEParams {

    /**
     * wordSearchId
     */
    wordSearchId?: number;

    /**
     * userId
     */
    userId?: number;
  }
}

export { PracticeControllerService }
