/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * User Controller
 */
@Injectable({
  providedIn: 'root',
})
class UserControllerService extends __BaseService {
  static readonly purchaseUsingPUTPath = '/api/users/purchase';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * purchase
   * @param params The `UserControllerService.PurchaseUsingPUTParams` containing the following parameters:
   *
   * - `wordsCollectionId`: wordsCollectionId
   *
   * - `userId`: userId
   */
  purchaseUsingPUTResponse(params: UserControllerService.PurchaseUsingPUTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wordsCollectionId != null) __params = __params.set('wordsCollectionId', params.wordsCollectionId.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/users/purchase`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * purchase
   * @param params The `UserControllerService.PurchaseUsingPUTParams` containing the following parameters:
   *
   * - `wordsCollectionId`: wordsCollectionId
   *
   * - `userId`: userId
   */
  purchaseUsingPUT(params: UserControllerService.PurchaseUsingPUTParams): __Observable<null> {
    return this.purchaseUsingPUTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserControllerService {

  /**
   * Parameters for purchaseUsingPUT
   */
  export interface PurchaseUsingPUTParams {

    /**
     * wordsCollectionId
     */
    wordsCollectionId?: number;

    /**
     * userId
     */
    userId?: number;
  }
}

export { UserControllerService }
