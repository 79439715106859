import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  KEY_SOMETHING_WENT_WRONG = "Something went wrong";
  KEY_LOGIN_FAILED = "Login failed. Please try again.";
  KEY_ACCOUNT_CREATED = "Your account has been created";
  KEY_PRACTICE = "Practice";
  KEY_AN_EMAIL_HAS_BEEN_SENT = "An email has been sent to";
  KEY_PUNCH_CARD_NO_BALANCE = "You don't have balance. Please purchase class package first.";
  KEY_YOUR_VOCABULARY = "Your Vocabulary";
  KEY_UPDATED_SUCCESSFULLY = "Updated Successfully";
  KEY_LOADING_YOUR_VOCABULARY = "Loading your vocabulary";
  KEY_LOADING_YOUR_TAGS = "Loading your tags";
  KEY_LOADING = "Loading";
  KEY_PURCHASING = "Purchasing";
  KEY_REMEMBERING = "Remembering";
  KEY_REVIEW_NEEDED = "Review needed";
  KEY_REMEMBERED = "Remembered";
  KEY_FORGOTTEN = "Forgotten";
  KEY_ALL_TIME = "All time";
  KEY_IF_YOU_DONT_REVIEW_THEM_SOON = "If you don't review them soon, you will forget them.";
  KEY_YOUR_BRAIN_IS_WORKING_ON_THEM = "Your brain is working on them.";
  KEY_YOU_TOTALLY_NAILED_THESE_WORDS = "You totally nailed these words.";
  KEY_YOU_FORGOT_TO_REVIEW_THEM_IN_TIME = "You forgot to review them in time.";
  KEY_PICTURES = "Pictures";
  KEY_STICKIES = "Stickies";
  KEY_TAGS = "Tags";
  KEY_YOU_HAVE_REMEMBERED = "You have remembered";
  KEY_YOU_SELECTED_CHINESE_SET_DICTIONARY_TO_CHINESE = "YOU_SELECTED_CHINESE_SET_DICTIONARY_TO_CHINESE";
  KEY_YOU_JUST_STUMBLED_UPON_A_NEW_WORD ="You added a new word to your vocabulary.";
  KEY_MARKED_AS_REMEMBERED = "marked as remembered.";
  KEY_REMOVE = "Remove";
  KEY_REMOVED = "removed";
  KEY_YOU_REMEMBERED = "You've remembered";

  keys: string[] = [this.KEY_SOMETHING_WENT_WRONG, this.KEY_LOGIN_FAILED, this.KEY_ACCOUNT_CREATED, 
  this.KEY_PRACTICE,
  this.KEY_AN_EMAIL_HAS_BEEN_SENT, this.KEY_PUNCH_CARD_NO_BALANCE, this.KEY_YOUR_VOCABULARY, 
  this.KEY_UPDATED_SUCCESSFULLY, this.KEY_LOADING_YOUR_VOCABULARY, this.KEY_LOADING_YOUR_TAGS,
  this.KEY_LOADING, this.KEY_PURCHASING, this.KEY_REMEMBERING, this.KEY_REVIEW_NEEDED,
  this.KEY_REMEMBERED, this.KEY_FORGOTTEN, this.KEY_ALL_TIME,
  this.KEY_IF_YOU_DONT_REVIEW_THEM_SOON, this.KEY_YOUR_BRAIN_IS_WORKING_ON_THEM,
  this.KEY_YOU_TOTALLY_NAILED_THESE_WORDS, this.KEY_YOU_FORGOT_TO_REVIEW_THEM_IN_TIME,
  this.KEY_PICTURES, this.KEY_STICKIES, this.KEY_TAGS, this.KEY_YOU_HAVE_REMEMBERED,
  this.KEY_YOU_SELECTED_CHINESE_SET_DICTIONARY_TO_CHINESE,
  this.KEY_YOU_JUST_STUMBLED_UPON_A_NEW_WORD,
  this.KEY_MARKED_AS_REMEMBERED, this.KEY_REMOVE, this.KEY_YOU_REMEMBERED, this.KEY_REMOVED
  ];
  private texts = {};

  /*
  somethingWentWrong: string;
  accountCreated: string;
  privateLession: string;
  anEmailHasBeenSent: string;
  loginFailed: string;
  punchCardNoBalance: string;
  */

  constructor(private translate: TranslateService) {
    this.initTexts();
    this.translate.onLangChange.subscribe(
      () => {
        this.initTexts();
      }
    )
  }

  initTexts() {
    this.keys.forEach((key) => {
      this.translate.get(key).subscribe(
        (txt) => {
          this.texts[key] = txt;
        }
      )
    })
  }

  getText(key: string): string {
    let text = this.texts[key];
    if (text == null)
      return key;
    else
      return text;
  }
}
