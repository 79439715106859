export { AuthenticateControllerService } from './services/authenticate-controller.service';
export { DataManagementControllerService } from './services/data-management-controller.service';
export { DeckControllerService } from './services/deck-controller.service';
export { DictionaryControllerService } from './services/dictionary-controller.service';
export { PracticeControllerService } from './services/practice-controller.service';
export { SecurityControllerService } from './services/security-controller.service';
export { TaskQueueControllerService } from './services/task-queue-controller.service';
export { UserNotesControllerService } from './services/user-notes-controller.service';
export { UserControllerService } from './services/user-controller.service';
export { BasicErrorControllerService } from './services/basic-error-controller.service';
export { ZombieControllerService } from './services/zombie-controller.service';
