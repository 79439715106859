import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LocalStorageService } from './data/local-storage.service';
import { SharedDataService } from './data/shared-data.service';
import { Router } from '@angular/router';
import { Figtionary2DataService } from './data/figtionary2-data.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

var that: AppComponent;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.scss'],
})
export class AppComponent {
  public appPages = [
    {
      titleEn: 'Home',
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      titleEn: 'Dictionary',
      title: 'Dictionary',
      url: '/dictionary',
      icon: 'home'
    },
    {
      titleEn: 'Review Vocab',
      title: 'Review Vocab',
      url: '/game',
      icon: 'logo-game-controller-b'
    },
    /*
    {
      titleEn: 'Prep Exams',
      title: 'Prep Exams',
      url: '/prep-exams',
      icon: 'logo-game-controller-b'
    },    
    {
      title: 'Game',
      url: '/hangman',
      icon: 'logo-game-controller-b'
    },
    */
      /*}, {
        title: 'Game(Tag)',
        url: '/hangman-by-tag',
        icon: 'logo-game-controller-b'
      */
     {
      titleEn: 'Search My Vocabulary',
      title: 'Search My Vocabulary',
      url: '/find-my-word',
      icon: 'logo-wordpress'
    }, {
      titleEn: 'Decks',
      title: 'Decks',
      url: '/decks',
      icon: 'logo-wordpress'
    },/* {
      titleEn: 'Word of The Day',
      title: 'Word of The Day',
      url: '/word-of-the-day',
      icon: 'logo-wordpress'
    },*/
    {
      titleEn: 'Profile',
      title: 'Profile',
      url: '/profile',
      icon: 'person'
    }, {
      titleEn: 'Contact Us',
      title: 'Contact Us',
      url: '/support',
      icon: 'mail'
    }
  ];

  constructor(
    private platform: Platform
    , private splashScreen: SplashScreen
    , private statusBar: StatusBar
    , private sharedData: SharedDataService
    , private translate: TranslateService
    , private localStorage: LocalStorageService
  ) {
    that = this;
    toggleDarkTheme(true);

    // Add or remove the "dark" class based on if the media query matches
    function toggleDarkTheme(shouldAdd) {
      document.body.classList.toggle('dark', shouldAdd);
    }
    this.initMyApp();
    this.initializeApp();
  }

  initializeApp() {        
    console.log("### (After)  id token: " + this.sharedData.idToken);
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.platform.resume.subscribe(
      () => {
        //console.log("Resuming from background.");
        this.sharedData.tryRefreshIdToken();
      }
    )
  }

  async initMyApp(){
    this.translate.setDefaultLang('en');
    //this.translate.setDefaultLang('zh-Hant');

    var lang = "en";    
    await this.localStorage.getLang().then(
      (savedLang)=>{
        if(savedLang != null){
          lang = savedLang;
        }        
      }
    )    
    console.log("use lang "+ lang);
    //this.translate.reloadLang(lang);
    this.translate.use(lang);

    console.log("languages :" + JSON.stringify(this.translate.translations));

    this.translate.onLangChange.subscribe(
      (event:LangChangeEvent)=>{
        this.changeMenuLanguage();
      }
    )
    
    
    this.changeMenuLanguage();

    // prevent possible immature http requests.
    this.lockNonTokenRequests();

    await this.sharedData.getAppVersion();
    console.log("### App Version: " + this.sharedData.appVersion);
    setInterval(that.sharedData.tryRefreshIdToken, (that.sharedData.minutes_refresh_before_expiration - 1) * 60 * 1000);
    console.log("### (Before) id token: " + this.sharedData.idToken);
    
    this.sharedData.idTokenRelease();

    await this.sharedData.tryRefreshIdToken().then(
      async (token) => {
        
      }
    );
  }

  private async lockNonTokenRequests(){        
    this.sharedData.idTokenRelease = await this.sharedData.idTokenMutex.acquire();
  }

  changeMenuLanguage(){
    this.appPages.forEach((page)=>{
      this.translate.get(page.titleEn).subscribe(
        (translated)=>{
          // console.log("menu title: "+translated);
          page.title = translated;
        }
      )
    })
  }
}
