/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserDto } from '../models/user-dto';

/**
 * Security Controller
 */
@Injectable({
  providedIn: 'root',
})
class SecurityControllerService extends __BaseService {
  static readonly getUserByExternalAuthUserIdUsingPOSTPath = '/api/security/getUserByExternalAuthUserId';
  static readonly registerUsingPOSTPath = '/api/security/register';
  static readonly saveUsingPOSTPath = '/api/security/save';
  static readonly saveProfilePictureUsingPOSTPath = '/api/security/saveProfilePicture';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getUserByExternalAuthUserId
   * @param externalAuthId externalAuthId
   * @return OK
   */
  getUserByExternalAuthUserIdUsingPOSTResponse(externalAuthId?: string): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (externalAuthId != null) __params = __params.set('externalAuthId', externalAuthId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/security/getUserByExternalAuthUserId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }
  /**
   * getUserByExternalAuthUserId
   * @param externalAuthId externalAuthId
   * @return OK
   */
  getUserByExternalAuthUserIdUsingPOST(externalAuthId?: string): __Observable<UserDto> {
    return this.getUserByExternalAuthUserIdUsingPOSTResponse(externalAuthId).pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  /**
   * register
   * @param params The `SecurityControllerService.RegisterUsingPOSTParams` containing the following parameters:
   *
   * - `refreshToken`:
   *
   * - `profilePictureUrl`:
   *
   * - `preferredDictionaryId`:
   *
   * - `lastName`:
   *
   * - `id`:
   *
   * - `firstName`:
   *
   * - `externalAuthUserId`:
   *
   * - `emailAddress`:
   *
   * @return OK
   */
  registerUsingPOSTResponse(params: SecurityControllerService.RegisterUsingPOSTParams): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.refreshToken != null) __params = __params.set('refreshToken', params.refreshToken.toString());
    if (params.profilePictureUrl != null) __params = __params.set('profilePictureUrl', params.profilePictureUrl.toString());
    if (params.preferredDictionaryId != null) __params = __params.set('preferredDictionaryId', params.preferredDictionaryId.toString());
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString());
    if (params.externalAuthUserId != null) __params = __params.set('externalAuthUserId', params.externalAuthUserId.toString());
    if (params.emailAddress != null) __params = __params.set('emailAddress', params.emailAddress.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/security/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }
  /**
   * register
   * @param params The `SecurityControllerService.RegisterUsingPOSTParams` containing the following parameters:
   *
   * - `refreshToken`:
   *
   * - `profilePictureUrl`:
   *
   * - `preferredDictionaryId`:
   *
   * - `lastName`:
   *
   * - `id`:
   *
   * - `firstName`:
   *
   * - `externalAuthUserId`:
   *
   * - `emailAddress`:
   *
   * @return OK
   */
  registerUsingPOST(params: SecurityControllerService.RegisterUsingPOSTParams): __Observable<UserDto> {
    return this.registerUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  /**
   * save
   * @param params The `SecurityControllerService.SaveUsingPOSTParams` containing the following parameters:
   *
   * - `refreshToken`:
   *
   * - `profilePictureUrl`:
   *
   * - `preferredDictionaryId`:
   *
   * - `lastName`:
   *
   * - `id`:
   *
   * - `firstName`:
   *
   * - `externalAuthUserId`:
   *
   * - `emailAddress`:
   *
   * @return OK
   */
  saveUsingPOSTResponse(params: SecurityControllerService.SaveUsingPOSTParams): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.refreshToken != null) __params = __params.set('refreshToken', params.refreshToken.toString());
    if (params.profilePictureUrl != null) __params = __params.set('profilePictureUrl', params.profilePictureUrl.toString());
    if (params.preferredDictionaryId != null) __params = __params.set('preferredDictionaryId', params.preferredDictionaryId.toString());
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString());
    if (params.externalAuthUserId != null) __params = __params.set('externalAuthUserId', params.externalAuthUserId.toString());
    if (params.emailAddress != null) __params = __params.set('emailAddress', params.emailAddress.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/security/save`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }
  /**
   * save
   * @param params The `SecurityControllerService.SaveUsingPOSTParams` containing the following parameters:
   *
   * - `refreshToken`:
   *
   * - `profilePictureUrl`:
   *
   * - `preferredDictionaryId`:
   *
   * - `lastName`:
   *
   * - `id`:
   *
   * - `firstName`:
   *
   * - `externalAuthUserId`:
   *
   * - `emailAddress`:
   *
   * @return OK
   */
  saveUsingPOST(params: SecurityControllerService.SaveUsingPOSTParams): __Observable<UserDto> {
    return this.saveUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  /**
   * saveProfilePicture
   * @param params The `SecurityControllerService.SaveProfilePictureUsingPOSTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `url`: url
   */
  saveProfilePictureUsingPOSTResponse(params: SecurityControllerService.SaveProfilePictureUsingPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.url != null) __params = __params.set('url', params.url.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/security/saveProfilePicture`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * saveProfilePicture
   * @param params The `SecurityControllerService.SaveProfilePictureUsingPOSTParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `url`: url
   */
  saveProfilePictureUsingPOST(params: SecurityControllerService.SaveProfilePictureUsingPOSTParams): __Observable<null> {
    return this.saveProfilePictureUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SecurityControllerService {

  /**
   * Parameters for registerUsingPOST
   */
  export interface RegisterUsingPOSTParams {
    refreshToken?: string;
    profilePictureUrl?: string;
    preferredDictionaryId?: string;
    lastName?: string;
    id?: number;
    firstName?: string;
    externalAuthUserId?: string;
    emailAddress?: string;
  }

  /**
   * Parameters for saveUsingPOST
   */
  export interface SaveUsingPOSTParams {
    refreshToken?: string;
    profilePictureUrl?: string;
    preferredDictionaryId?: string;
    lastName?: string;
    id?: number;
    firstName?: string;
    externalAuthUserId?: string;
    emailAddress?: string;
  }

  /**
   * Parameters for saveProfilePictureUsingPOST
   */
  export interface SaveProfilePictureUsingPOSTParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * url
     */
    url?: string;
  }
}

export { SecurityControllerService }
