/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserDashboardDto } from '../models/user-dashboard-dto';
import { FindWordsByIndexDto } from '../models/find-words-by-index-dto';
import { UserDefinitionDto } from '../models/user-definition-dto';
import { UserNoteDto } from '../models/user-note-dto';
import { SaveTagsDto } from '../models/save-tags-dto';

/**
 * User Notes Controller
 */
@Injectable({
  providedIn: 'root',
})
class UserNotesControllerService extends __BaseService {
  static readonly addUserWordTagsUsingPOSTPath = '/api/userNotes/__addUserWordTags';
  static readonly deleteUserWordTagsUsingPOSTPath = '/api/userNotes/__deleteUserWordTags';
  static readonly getUserDashboardUsingGETPath = '/api/userNotes/dashboard';
  static readonly findTagsByWordUsingGETPath = '/api/userNotes/findTagsByWord';
  static readonly findWordsUsingGETPath = '/api/userNotes/findWords';
  static readonly findWordsByTagUsingGETPath = '/api/userNotes/findWordsByTag';
  static readonly findWordsByTagWordSearchDaoUsingGETPath = '/api/userNotes/findWordsByTag_WordSearchDao';
  static readonly getUserTagsUsingGETPath = '/api/userNotes/getUserTags';
  static readonly getUserPracticeDashboardUsingGETPath = '/api/userNotes/practiceDashboard';
  static readonly saveUserDefinitionsUsingPUTPath = '/api/userNotes/userDefinitions';
  static readonly deleteUserDefinitionUsingDELETEPath = '/api/userNotes/userDefinitions';
  static readonly userNotesUsingGETPath = '/api/userNotes/userNotes';
  static readonly saveUserNoteUsingPUTPath = '/api/userNotes/userNotes';
  static readonly findV1UserEntriesUsingGETPath = '/api/userNotes/v1UserEntries';
  static readonly saveUserWordTagsUsingPUTPath = '/api/userNotes/wordTags';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * addUserWordTags
   * @param params The `UserNotesControllerService.AddUserWordTagsUsingPOSTParams` containing the following parameters:
   *
   * - `wordText`: wordText
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  addUserWordTagsUsingPOSTResponse(params: UserNotesControllerService.AddUserWordTagsUsingPOSTParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wordText != null) __params = __params.set('wordText', params.wordText.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.tagText != null) __params = __params.set('tagText', params.tagText.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/userNotes/__addUserWordTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * addUserWordTags
   * @param params The `UserNotesControllerService.AddUserWordTagsUsingPOSTParams` containing the following parameters:
   *
   * - `wordText`: wordText
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  addUserWordTagsUsingPOST(params: UserNotesControllerService.AddUserWordTagsUsingPOSTParams): __Observable<number> {
    return this.addUserWordTagsUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * deleteUserWordTags
   * @param params The `UserNotesControllerService.DeleteUserWordTagsUsingPOSTParams` containing the following parameters:
   *
   * - `wordText`: wordText
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  deleteUserWordTagsUsingPOSTResponse(params: UserNotesControllerService.DeleteUserWordTagsUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wordText != null) __params = __params.set('wordText', params.wordText.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.tagText != null) __params = __params.set('tagText', params.tagText.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/userNotes/__deleteUserWordTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * deleteUserWordTags
   * @param params The `UserNotesControllerService.DeleteUserWordTagsUsingPOSTParams` containing the following parameters:
   *
   * - `wordText`: wordText
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  deleteUserWordTagsUsingPOST(params: UserNotesControllerService.DeleteUserWordTagsUsingPOSTParams): __Observable<string> {
    return this.deleteUserWordTagsUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * getUserDashboard
   * @param userId userId
   * @return OK
   */
  getUserDashboardUsingGETResponse(userId?: number): __Observable<__StrictHttpResponse<UserDashboardDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userNotes/dashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDashboardDto>;
      })
    );
  }
  /**
   * getUserDashboard
   * @param userId userId
   * @return OK
   */
  getUserDashboardUsingGET(userId?: number): __Observable<UserDashboardDto> {
    return this.getUserDashboardUsingGETResponse(userId).pipe(
      __map(_r => _r.body as UserDashboardDto)
    );
  }

  /**
   * findTagsByWord
   * @param params The `UserNotesControllerService.FindTagsByWordUsingGETParams` containing the following parameters:
   *
   * - `wordText`: wordText
   *
   * - `userId`: userId
   *
   * @return OK
   */
  findTagsByWordUsingGETResponse(params: UserNotesControllerService.FindTagsByWordUsingGETParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wordText != null) __params = __params.set('wordText', params.wordText.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userNotes/findTagsByWord`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * findTagsByWord
   * @param params The `UserNotesControllerService.FindTagsByWordUsingGETParams` containing the following parameters:
   *
   * - `wordText`: wordText
   *
   * - `userId`: userId
   *
   * @return OK
   */
  findTagsByWordUsingGET(params: UserNotesControllerService.FindTagsByWordUsingGETParams): __Observable<Array<string>> {
    return this.findTagsByWordUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * findWords
   * @param params The `UserNotesControllerService.FindWordsUsingGETParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `text`: text
   *
   * @return OK
   */
  findWordsUsingGETResponse(params: UserNotesControllerService.FindWordsUsingGETParams): __Observable<__StrictHttpResponse<FindWordsByIndexDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.text != null) __params = __params.set('text', params.text.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userNotes/findWords`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FindWordsByIndexDto>;
      })
    );
  }
  /**
   * findWords
   * @param params The `UserNotesControllerService.FindWordsUsingGETParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `text`: text
   *
   * @return OK
   */
  findWordsUsingGET(params: UserNotesControllerService.FindWordsUsingGETParams): __Observable<FindWordsByIndexDto> {
    return this.findWordsUsingGETResponse(params).pipe(
      __map(_r => _r.body as FindWordsByIndexDto)
    );
  }

  /**
   * findWordsByTag
   * @param params The `UserNotesControllerService.FindWordsByTagUsingGETParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  findWordsByTagUsingGETResponse(params: UserNotesControllerService.FindWordsByTagUsingGETParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.tagText != null) __params = __params.set('tagText', params.tagText.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userNotes/findWordsByTag`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * findWordsByTag
   * @param params The `UserNotesControllerService.FindWordsByTagUsingGETParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  findWordsByTagUsingGET(params: UserNotesControllerService.FindWordsByTagUsingGETParams): __Observable<Array<string>> {
    return this.findWordsByTagUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * findWordsByTag_WordSearchDao
   * @param params The `UserNotesControllerService.FindWordsByTagWordSearchDaoUsingGETParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  findWordsByTagWordSearchDaoUsingGETResponse(params: UserNotesControllerService.FindWordsByTagWordSearchDaoUsingGETParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.tagText != null) __params = __params.set('tagText', params.tagText.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userNotes/findWordsByTag_WordSearchDao`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * findWordsByTag_WordSearchDao
   * @param params The `UserNotesControllerService.FindWordsByTagWordSearchDaoUsingGETParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `tagText`: tagText
   *
   * @return OK
   */
  findWordsByTagWordSearchDaoUsingGET(params: UserNotesControllerService.FindWordsByTagWordSearchDaoUsingGETParams): __Observable<Array<string>> {
    return this.findWordsByTagWordSearchDaoUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * getUserTags
   * @param userId userId
   * @return OK
   */
  getUserTagsUsingGETResponse(userId?: number): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userNotes/getUserTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * getUserTags
   * @param userId userId
   * @return OK
   */
  getUserTagsUsingGET(userId?: number): __Observable<Array<string>> {
    return this.getUserTagsUsingGETResponse(userId).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * getUserPracticeDashboard
   * @param userId userId
   * @return OK
   */
  getUserPracticeDashboardUsingGETResponse(userId?: number): __Observable<__StrictHttpResponse<UserDashboardDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userNotes/practiceDashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDashboardDto>;
      })
    );
  }
  /**
   * getUserPracticeDashboard
   * @param userId userId
   * @return OK
   */
  getUserPracticeDashboardUsingGET(userId?: number): __Observable<UserDashboardDto> {
    return this.getUserPracticeDashboardUsingGETResponse(userId).pipe(
      __map(_r => _r.body as UserDashboardDto)
    );
  }

  /**
   * saveUserDefinitions
   * @param params The `UserNotesControllerService.SaveUserDefinitionsUsingPUTParams` containing the following parameters:
   *
   * - `word`:
   *
   * - `text`:
   *
   * - `pronunciationUrl`:
   *
   * - `partOfSpeech`:
   *
   * - `media.youTubeInfo.youTubeVideoId`:
   *
   * - `media.youTubeInfo.embedUrl`:
   *
   * - `media.url`:
   *
   * - `media.source`:
   *
   * - `media.image`:
   *
   * - `media.dinkyUrl`:
   *
   * - `id`:
   *
   * - `createdDateTime`:
   *
   * - `createdBy.profilePictureUrl`:
   *
   * - `createdBy.lastName`:
   *
   * - `createdBy.id`:
   *
   * - `createdBy.firstName`:
   *
   * @return OK
   */
  saveUserDefinitionsUsingPUTResponse(params: UserNotesControllerService.SaveUserDefinitionsUsingPUTParams): __Observable<__StrictHttpResponse<UserDefinitionDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.word != null) __params = __params.set('word', params.word.toString());
    if (params.text != null) __params = __params.set('text', params.text.toString());
    if (params.pronunciationUrl != null) __params = __params.set('pronunciationUrl', params.pronunciationUrl.toString());
    if (params.partOfSpeech != null) __params = __params.set('partOfSpeech', params.partOfSpeech.toString());
    if (params.mediaYouTubeInfoYouTubeVideoId != null) __params = __params.set('media.youTubeInfo.youTubeVideoId', params.mediaYouTubeInfoYouTubeVideoId.toString());
    if (params.mediaYouTubeInfoEmbedUrl != null) __params = __params.set('media.youTubeInfo.embedUrl', params.mediaYouTubeInfoEmbedUrl.toString());
    if (params.mediaUrl != null) __params = __params.set('media.url', params.mediaUrl.toString());
    if (params.mediaSource != null) __params = __params.set('media.source', params.mediaSource.toString());
    if (params.mediaImage != null) __params = __params.set('media.image', params.mediaImage.toString());
    if (params.mediaDinkyUrl != null) __params = __params.set('media.dinkyUrl', params.mediaDinkyUrl.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.createdDateTime != null) __params = __params.set('createdDateTime', params.createdDateTime.toString());
    if (params.createdByProfilePictureUrl != null) __params = __params.set('createdBy.profilePictureUrl', params.createdByProfilePictureUrl.toString());
    if (params.createdByLastName != null) __params = __params.set('createdBy.lastName', params.createdByLastName.toString());
    if (params.createdById != null) __params = __params.set('createdBy.id', params.createdById.toString());
    if (params.createdByFirstName != null) __params = __params.set('createdBy.firstName', params.createdByFirstName.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/userNotes/userDefinitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDefinitionDto>;
      })
    );
  }
  /**
   * saveUserDefinitions
   * @param params The `UserNotesControllerService.SaveUserDefinitionsUsingPUTParams` containing the following parameters:
   *
   * - `word`:
   *
   * - `text`:
   *
   * - `pronunciationUrl`:
   *
   * - `partOfSpeech`:
   *
   * - `media.youTubeInfo.youTubeVideoId`:
   *
   * - `media.youTubeInfo.embedUrl`:
   *
   * - `media.url`:
   *
   * - `media.source`:
   *
   * - `media.image`:
   *
   * - `media.dinkyUrl`:
   *
   * - `id`:
   *
   * - `createdDateTime`:
   *
   * - `createdBy.profilePictureUrl`:
   *
   * - `createdBy.lastName`:
   *
   * - `createdBy.id`:
   *
   * - `createdBy.firstName`:
   *
   * @return OK
   */
  saveUserDefinitionsUsingPUT(params: UserNotesControllerService.SaveUserDefinitionsUsingPUTParams): __Observable<UserDefinitionDto> {
    return this.saveUserDefinitionsUsingPUTResponse(params).pipe(
      __map(_r => _r.body as UserDefinitionDto)
    );
  }

  /**
   * deleteUserDefinition
   * @param params The `UserNotesControllerService.DeleteUserDefinitionUsingDELETEParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `userDefinitionId`: userDefinitionId
   */
  deleteUserDefinitionUsingDELETEResponse(params: UserNotesControllerService.DeleteUserDefinitionUsingDELETEParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.userDefinitionId != null) __params = __params.set('userDefinitionId', params.userDefinitionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/userNotes/userDefinitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * deleteUserDefinition
   * @param params The `UserNotesControllerService.DeleteUserDefinitionUsingDELETEParams` containing the following parameters:
   *
   * - `userId`: userId
   *
   * - `userDefinitionId`: userDefinitionId
   */
  deleteUserDefinitionUsingDELETE(params: UserNotesControllerService.DeleteUserDefinitionUsingDELETEParams): __Observable<null> {
    return this.deleteUserDefinitionUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * userNotes
   * @param params The `UserNotesControllerService.UserNotesUsingGETParams` containing the following parameters:
   *
   * - `word`: word
   *
   * - `userId`: userId
   *
   * @return OK
   */
  userNotesUsingGETResponse(params: UserNotesControllerService.UserNotesUsingGETParams): __Observable<__StrictHttpResponse<Array<UserNoteDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.word != null) __params = __params.set('word', params.word.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userNotes/userNotes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserNoteDto>>;
      })
    );
  }
  /**
   * userNotes
   * @param params The `UserNotesControllerService.UserNotesUsingGETParams` containing the following parameters:
   *
   * - `word`: word
   *
   * - `userId`: userId
   *
   * @return OK
   */
  userNotesUsingGET(params: UserNotesControllerService.UserNotesUsingGETParams): __Observable<Array<UserNoteDto>> {
    return this.userNotesUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<UserNoteDto>)
    );
  }

  /**
   * saveUserNote
   * @param params The `UserNotesControllerService.SaveUserNoteUsingPUTParams` containing the following parameters:
   *
   * - `word`:
   *
   * - `userId`:
   *
   * - `text`:
   *
   * - `id`:
   *
   * @return OK
   */
  saveUserNoteUsingPUTResponse(params: UserNotesControllerService.SaveUserNoteUsingPUTParams): __Observable<__StrictHttpResponse<UserNoteDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.word != null) __params = __params.set('word', params.word.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.text != null) __params = __params.set('text', params.text.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/userNotes/userNotes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserNoteDto>;
      })
    );
  }
  /**
   * saveUserNote
   * @param params The `UserNotesControllerService.SaveUserNoteUsingPUTParams` containing the following parameters:
   *
   * - `word`:
   *
   * - `userId`:
   *
   * - `text`:
   *
   * - `id`:
   *
   * @return OK
   */
  saveUserNoteUsingPUT(params: UserNotesControllerService.SaveUserNoteUsingPUTParams): __Observable<UserNoteDto> {
    return this.saveUserNoteUsingPUTResponse(params).pipe(
      __map(_r => _r.body as UserNoteDto)
    );
  }

  /**
   * findV1UserEntries
   * @param params The `UserNotesControllerService.FindV1UserEntriesUsingGETParams` containing the following parameters:
   *
   * - `word`: word
   *
   * - `userId`: userId
   *
   * @return OK
   */
  findV1UserEntriesUsingGETResponse(params: UserNotesControllerService.FindV1UserEntriesUsingGETParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.word != null) __params = __params.set('word', params.word.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userNotes/v1UserEntries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * findV1UserEntries
   * @param params The `UserNotesControllerService.FindV1UserEntriesUsingGETParams` containing the following parameters:
   *
   * - `word`: word
   *
   * - `userId`: userId
   *
   * @return OK
   */
  findV1UserEntriesUsingGET(params: UserNotesControllerService.FindV1UserEntriesUsingGETParams): __Observable<Array<string>> {
    return this.findV1UserEntriesUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * saveUserWordTags
   * @param params The `UserNotesControllerService.SaveUserWordTagsUsingPUTParams` containing the following parameters:
   *
   * - `word`:
   *
   * - `userId`:
   *
   * - `tags`:
   *
   * @return OK
   */
  saveUserWordTagsUsingPUTResponse(params: UserNotesControllerService.SaveUserWordTagsUsingPUTParams): __Observable<__StrictHttpResponse<SaveTagsDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.word != null) __params = __params.set('word', params.word.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    (params.tags || []).forEach(val => {if (val != null) __params = __params.append('tags', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/userNotes/wordTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SaveTagsDto>;
      })
    );
  }
  /**
   * saveUserWordTags
   * @param params The `UserNotesControllerService.SaveUserWordTagsUsingPUTParams` containing the following parameters:
   *
   * - `word`:
   *
   * - `userId`:
   *
   * - `tags`:
   *
   * @return OK
   */
  saveUserWordTagsUsingPUT(params: UserNotesControllerService.SaveUserWordTagsUsingPUTParams): __Observable<SaveTagsDto> {
    return this.saveUserWordTagsUsingPUTResponse(params).pipe(
      __map(_r => _r.body as SaveTagsDto)
    );
  }
}

module UserNotesControllerService {

  /**
   * Parameters for addUserWordTagsUsingPOST
   */
  export interface AddUserWordTagsUsingPOSTParams {

    /**
     * wordText
     */
    wordText?: string;

    /**
     * userId
     */
    userId?: number;

    /**
     * tagText
     */
    tagText?: string;
  }

  /**
   * Parameters for deleteUserWordTagsUsingPOST
   */
  export interface DeleteUserWordTagsUsingPOSTParams {

    /**
     * wordText
     */
    wordText?: string;

    /**
     * userId
     */
    userId?: number;

    /**
     * tagText
     */
    tagText?: string;
  }

  /**
   * Parameters for findTagsByWordUsingGET
   */
  export interface FindTagsByWordUsingGETParams {

    /**
     * wordText
     */
    wordText?: string;

    /**
     * userId
     */
    userId?: number;
  }

  /**
   * Parameters for findWordsUsingGET
   */
  export interface FindWordsUsingGETParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * text
     */
    text?: string;
  }

  /**
   * Parameters for findWordsByTagUsingGET
   */
  export interface FindWordsByTagUsingGETParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * tagText
     */
    tagText?: string;
  }

  /**
   * Parameters for findWordsByTagWordSearchDaoUsingGET
   */
  export interface FindWordsByTagWordSearchDaoUsingGETParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * tagText
     */
    tagText?: string;
  }

  /**
   * Parameters for saveUserDefinitionsUsingPUT
   */
  export interface SaveUserDefinitionsUsingPUTParams {
    word?: string;
    text?: string;
    pronunciationUrl?: string;
    partOfSpeech?: string;
    mediaYouTubeInfoYouTubeVideoId?: string;
    mediaYouTubeInfoEmbedUrl?: string;
    mediaUrl?: string;
    mediaSource?: string;
    mediaImage?: boolean;
    mediaDinkyUrl?: string;
    id?: number;
    createdDateTime?: string;
    createdByProfilePictureUrl?: string;
    createdByLastName?: string;
    createdById?: number;
    createdByFirstName?: string;
  }

  /**
   * Parameters for deleteUserDefinitionUsingDELETE
   */
  export interface DeleteUserDefinitionUsingDELETEParams {

    /**
     * userId
     */
    userId?: number;

    /**
     * userDefinitionId
     */
    userDefinitionId?: number;
  }

  /**
   * Parameters for userNotesUsingGET
   */
  export interface UserNotesUsingGETParams {

    /**
     * word
     */
    word?: string;

    /**
     * userId
     */
    userId?: number;
  }

  /**
   * Parameters for saveUserNoteUsingPUT
   */
  export interface SaveUserNoteUsingPUTParams {
    word?: string;
    userId?: number;
    text?: string;
    id?: number;
  }

  /**
   * Parameters for findV1UserEntriesUsingGET
   */
  export interface FindV1UserEntriesUsingGETParams {

    /**
     * word
     */
    word?: string;

    /**
     * userId
     */
    userId?: number;
  }

  /**
   * Parameters for saveUserWordTagsUsingPUT
   */
  export interface SaveUserWordTagsUsingPUTParams {
    word?: string;
    userId?: number;
    tags?: Array<string>;
  }
}

export { UserNotesControllerService }
