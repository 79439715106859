import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { LocalStorageService } from './local-storage.service';
import { Figtionary2DataService } from './figtionary2-data.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Mutex } from 'async-mutex';
import { Observable, Observer } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { HelperService } from './helper.service';

const APP_VERSION = "1.1.23";
var that: SharedDataService;
@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  public refreshToken: string;
  public idToken: string;
  public appVersion: string;
  private shouldUpdateApp: boolean = null;
 
  idTokenRelease: any;
  idTokenMutex: Mutex;

  constructor(private appVersionComp: AppVersion,
    private localStorage: LocalStorageService,
    private figtionary2Service: Figtionary2DataService,
    private loadingController: LoadingController,    
    private helperService: HelperService
  ) {

    that = this;
    this.idTokenMutex = new Mutex();
  }
  checkAppCompatibility(): Observable<boolean> {
    return Observable.create(async (observer: Observer<any>) => {
      if (this.shouldUpdateApp != null) {
        observer.next(this.shouldUpdateApp);
        observer.complete();
      } else {
        await this.helperService.presentLoading("", this.loadingController);
        this.figtionary2Service.shouldUpdateApp().subscribe(
          (should) => {
            this.loadingController.dismiss();
            this.shouldUpdateApp = should;
            observer.next(this.shouldUpdateApp);
            observer.complete();
          }, (error) => {
            this.loadingController.dismiss();
            observer.error(error);
          }
        )
      }
    });
  }

  async getAppVersion() {
    await this.appVersionComp.getVersionNumber().then(
      (versionNumber) => {
        this.appVersion = versionNumber;
        //this.sharedData.appVersion = "1.0.6";
      }, (error) => {
        console.log(error);
        this.appVersion = APP_VERSION;
      }
    )
  }

  minutes_refresh_before_expiration = 15;
  shouldGetNewIdToken(): boolean {
    if (that.idToken == null)
      return true;
    let jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(that.idToken);
    const expirationDate = jwtHelper.getTokenExpirationDate(that.idToken);
    console.log("id token expiration at " + expirationDate.toLocaleTimeString());
    let now = new Date();
    let minutesBeforeExpiration = (expirationDate.getTime() - now.getTime()) / 60000;
    //if(now > expirationDate){
    if (minutesBeforeExpiration < that.minutes_refresh_before_expiration) {
      return true;
    } else {
      return false;
    }
  }

  async tryRefreshIdToken() {
    that.idTokenRelease = await that.idTokenMutex.acquire();

    var promise = new Promise<string>((resolve, reject) => {
      console.log("Promise: trying to refresh id token.");
      if (that.shouldGetNewIdToken()) {
        console.log("Promise: Id token expired. Getting a new one.");
        that.localStorage.getUser().subscribe(
          (user) => {
            if (user == null) {
              that.localStorage.setUser(null);
              that.idTokenRelease();
            } else {
              that.figtionary2Service.tokens(user.refreshToken).subscribe(
                (success) => {
                  console.log("Promise: Got a new ID Token: "+success);                  
                  const jwtHelper = new JwtHelperService();
                  that.idToken = success.id_token;
                  if(that.idToken != null){
                    const expirationDate = jwtHelper.getTokenExpirationDate(that.idToken);                
                    console.log("ID token expires @ " + expirationDate.toLocaleTimeString());
                  }
                  resolve(success.id_token);
                  that.idTokenRelease();
                }, (error) => {
                  reject(error);
                  that.idTokenRelease();
                }
              )
            }
          }, (error) => {
            reject(error);
            that.idTokenRelease();
          })
      } else {
        console.log("Promise: Id token is still valid.");
        resolve(that.idToken);
        that.idTokenRelease();
      }
    })
    return promise;
  }
}
